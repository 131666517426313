var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[(_vm.selectedResponse && _vm.selectedResponse != null)?_c('b-sidebar',{attrs:{"id":("sidebar-question-details-" + (_vm.selectedResponse._id)),"sidebar-class":"sidebar-lg","bg-variant":"white","backdrop":"","right":"","no-header":"","shadow":"","lazy":"","width":"620px"}},[_c('sidebar-template',{attrs:{"title":"Response Details","closeAction":function () {
          _vm.closeDetailsSidebar(_vm.selectedResponse._id);
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.selectedResponse && _vm.selectedResponse != null)?_c('response-question-details-view',{attrs:{"responseId":_vm.selectedResponse._id,"questionnaireId":_vm.selectedResponse.questionnaire_id,"reloadParent":_vm.load,"assessmentStatus":Number(_vm.assessmentStatus)}}):_vm._e()]},proxy:true}],null,false,1951603110)})],1):_vm._e(),_c('div',{staticClass:"rounded cursor-pointer shadow border-light d-flex flex-column align-items-start justify-content-start my-2 px-0 w-100 border",class:{ 'border-primary': _vm.isDropped },staticStyle:{"position":"relative"},on:{"click":function () {
        _vm.$router.push(
          ("/thirdpartyrisksRA/assessments/" + (_vm.$route.params.assessmentId) + "/vendor/" + (_vm.vendorQuestionnaire.vendor._id))
        );
      }}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100 py-50 px-1"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('b-badge',{staticClass:"mr-25",attrs:{"variant":"primary"}},[_vm._v("#"+_vm._s(_vm.index + 1))]),_c('div',{staticClass:"d-flex align-items-center justify-content-start px-1",staticStyle:{"width":"50vw"}},[_c('h4',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-secondary",modifiers:{"hover":true,"top":true,"v-secondary":true}}],staticClass:"mb-0 text-truncate",attrs:{"title":_vm.vendorQuestionnaire.vendor.name}},[_c('span',[_vm._v(_vm._s(_vm.vendorQuestionnaire.vendor.name))])])])],1),_c('div',{staticClass:"d-flex align-items-center justify-content-end px-1"},[(
            _vm.vendorQuestionnaire.vendor.vendor_category &&
            _vm.vendorQuestionnaire.vendor.vendor_category !== null
          )?_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start mr-3"},[_c('p',{staticClass:"mb-25 font-weight-bold",staticStyle:{"font-size":"12px"}},[_vm._v("Category")]),_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-info",modifiers:{"hover":true,"top":true,"v-info":true}}],staticClass:"text-truncate",staticStyle:{"max-width":"120px"},attrs:{"variant":"primary","title":_vm.vendorQuestionnaire.vendor.vendor_category}},[_vm._v("vendorQuestionnaire.vendor.vendor_category")])],1):_vm._e(),_c('progress-bar',{attrs:{"value":Number(_vm.vendorQuestionnaire.completion),"min":1,"max":100,"title":"Progress:"}})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-end",staticStyle:{"max-width":"25vw"}},[_c('b-button',{attrs:{"pill":"","variant":"outline-primary","size":"sm"},on:{"click":function($event){$event.stopPropagation();return (function () {
              _vm.$router.push(
                ("/thirdpartyrisksRA/assessments/" + (_vm.$route.params.assessmentId) + "/vendor/" + (_vm.vendorQuestionnaire.vendor._id))
              );
            }).apply(null, arguments)}}},[_vm._v("View Responses")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
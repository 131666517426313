<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card">
      <div class="card-header pb-75">
        <div class="d-flex align-items-start justify-content-between w-100">
          <div
            class="d-flex flex-column align-items-start justify-content-start"
            style="max-width: 60%"
          >
            <h3 class="mb-25 font-weight-bolder">{{ assessment.title }}</h3>
            <p class="mb-0">{{ assessment.description }}</p>
            <div class="mt-1 d-flex align-items-center justify-content-end">
              <b-button
                v-if="assessment.status === 3"
                size="sm"
                class="mr-50"
                @click="handlePublishClick(assessment._id)"
                variant="success"
                ><feather-icon class="mr-50" icon="ArrowUpCircleIcon" />Publish</b-button
              >
              <b-button
                size="sm"
                class="mr-50"
                @click="
                  () => {
                    $router.push(`/thirdpartyrisksRA/assessments/edit/${assessment._id}`);
                  }
                "
                variant="info"
                ><feather-icon class="mr-50" icon="EditIcon" />Edit</b-button
              >

              <b-button
                size="sm"
                @click="
                  () => {
                    $router.push(
                      `/thirdpartyrisksRA/assessments/edit/${assessment._id}?startIndex=2`
                    );
                  }
                "
                variant="outline-primary"
                ><feather-icon class="mr-50" icon="SendIcon" />Invite Third
                Party</b-button
              >
            </div>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-end">
            <div class="d-flex flex-column align-items-center justify-content-center">
              <donut-bar
                :percentage="Number(assessment.completion.toFixed(2))"
                :displayValue="assessment.completion + '%'"
                size="sm"
                :uniqueId="assessment._id + 2"
                :colorsReversed="true"
              />
              <p class="font-weight-bolder">Overall Progress</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header pb-0 border-bottom">
        <div class="d-flex flex-column align-items-start justify-content-start">
          <h4 class="mb-1">
            Third Parties
            <b-badge pill variant="warning" size="sm"
              ><span class="font-weight-bolder">{{
                assessment.groupedQuestionnaires.filter(item=>item.vendor).length
              }}</span></b-badge
            >
          </h4>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-sm-6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
        <!-- <div class="col-sm-6">
            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-form-select
                style="width: 300px"
                v-model="filters.status"
                :options="complianceOptions"
                placeholder="Filter Compliance"
              ></b-form-select>
            </div>
          </div> -->
      </div>
      <div class="card-body py-25 px-50">
        <template
          v-if="
            assessment.groupedQuestionnaires &&
            assessment.groupedQuestionnaires != null &&
            assessment.groupedQuestionnaires.length > 0
          "
        >
          <vendor-questionnaire-card
            v-for="(vendorQuestionnaire, index) in assessment.groupedQuestionnaires"
            :key="vendorQuestionnaire.vendor?vendorQuestionnaire.vendor._id:index"
            :vendorQuestionnaire="vendorQuestionnaire"
            :assessmentStatus="assessment.status"
            :index="index"
          />
        </template>
        <template v-else>
          <table class="w-100">
            <tbody>
              <empty-table-section title="Third Parties Empty">
                <template #content>
                  <p class="text-center">
                    It seems there are no Third Parties at the moment
                  </p>
                </template>
              </empty-table-section>
            </tbody>
          </table>
        </template>
      </div>
    </div>

    <!-- <div class="d-flex align-items-start justify-content-around">
      <div style="width: 25%" class="card" v-if="assessment">
        <div
          class="card-header d-flex flex-row align-items-start justify-content-between"
        >
          <div class="d-flex flex-column align-items-center justify-content-start w-100">
            <h2 class="mb-25 font-weight-bolder text-center">
              {{ assessment.title }}
            </h2>
            <p class="font-weight-bold text-center">
              {{ assessment.description }}
            </p>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex flex-column align-items-center justify-content-center">
            <donut-bar
              :percentage="Number(assessment.completion.toFixed(2))"
              :displayValue="assessment.completion + '%'"
              size="sm"
              :uniqueId="assessment._id + 2"
              :colorsReversed="true"
            />
            <p class="font-weight-bolder">Overall Progress</p>
          </div>
        </div>
      </div>

      <div style="width: 70%" class="card" v-if="assessment !== null">
        <div class="card-header pb-0 border-bottom">
          <div class="d-flex flex-column align-items-start justify-content-start">
            <h4 class="mb-1">
              Third Parties
              <b-badge pill variant="warning" size="sm"
                ><span class="font-weight-bolder">{{
                  assessment.groupedQuestionnaires.length
                }}</span></b-badge
              >
            </h4>
          </div>
        </div>
        <div class="card-body py-25 px-50" style="height: 65vh; overflow-y: auto">
          <vendor-questionnaire-card
            v-for="vendorQuestionnaire in assessment.groupedQuestionnaires"
            :key="vendorQuestionnaire.vendor._id"
            :vendorQuestionnaire="vendorQuestionnaire"
          />
        </div>
      </div>
    </div> -->
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BSidebar,
  BButton,
  BAvatar,
  BBadge,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import DonutBar from "@/components/DonutBar.vue";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import ThirdPartyRisksMixins from "../../../mixins/ThirdPartyRisksMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import VendorQuestionnaireCard from "./components/VendorQuestionnairesCard.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import EmptyTableSection from "../../../components/EmptyTableSection.vue";

export default {
  components: {
    BOverlay,
    BSidebar,
    BButton,
    BAvatar,
    DonutBar,
    VendorQuestionnaireCard,
    BBadge,
    VuePerfectScrollbar,
    EmptyTableSection,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      showOverlay: false,
      vendor: null,
      importedQuestionnaire: null,
      assessment: null,
      filters: {
        page: 1,
        search: null,
      },
      pagination: {
        perPage: 10,
        lastPage: 1,
      },
      isUserVendor: false,
    };
  },

  mixins: [
    ThirdPartyRisksMixins,
    ThirdPartyRisksRAMixins,
    QuestionnaireMixins,
    ResponseMixins,
    UtilsMixins,
    TaskMixins,
  ],

  computed: {
    filteredThirdParties() {
      return assessment.vendors.filter((v) =>
        v.name.toLowerCase().includes(filters.search)
      );
    },
  },

  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }
      this.getAssessmentDetailsAndSetData(this.$route.params.assessmentId);
    },

    handleEditClick(assessmentId) {
      // this.selectedAssessmentId = assessmentId;
      // this.openEditAssessmentSidebar();
      this.$router.push(`/thirdpartyrisksRA/assessments/edit/${assessmentId}`);
    },

    handlePublishClick(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to publish this Assessment?", {
          title: "Warning",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
          headerBgVariant: "light-warning",
        })
        .then((value) => {
          if (value == true) {
            this.handlePublishAssessment(id);
          }
        });
    },

    handlePublishAssessment(id) {
      this.showOverlay = true;
      this.updateTPRAssessment({ status: 1 }, id)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleQuestionnaireClick(questionnaireId) {
      //   this.$router.push(
      //     `/thirdpartyrisks/${this.$route.params.id}/details/assessment/${this.$route.params.assessmentId}/questionnaire/${questionnaireId}`
      //   );
    },

    getAssessmentDetailsAndSetData(id) {
      this.getAssessmentDetails(id)
        .then((res) => {
          this.assessment = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    getVendorDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getVendorDetails(id)
        .then((res) => {
          this.vendor = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getImportedQuestionnaireAndSetData(vendorId) {
      this.showOverlay = true;
      this.getQuestionnaire({ dont_paginate: true }, vendorId)
        .then((res) => {
          this.importedQuestionnaire = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeAssessmentSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-tpr-assessment");
    },
    openAssessmentSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-tpr-assessment");
      });
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
</style>

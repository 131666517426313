<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-sidebar
      v-if="selectedResponse && selectedResponse != null"
      :id="`sidebar-question-details-${selectedResponse._id}`"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="620px"
    >
      <sidebar-template
        title="Response Details"
        :closeAction="
          () => {
            closeDetailsSidebar(selectedResponse._id);
          }
        "
      >
        <template #content>
          <response-question-details-view
            v-if="selectedResponse && selectedResponse != null"
            :responseId="selectedResponse._id"
            :questionnaireId="selectedResponse.questionnaire_id"
            :reloadParent="load"
            :assessmentStatus="Number(assessmentStatus)"
          />
        </template>
      </sidebar-template>
    </b-sidebar>
    <div
      class="rounded cursor-pointer shadow border-light d-flex flex-column align-items-start justify-content-start my-2 px-0 w-100 border"
      :class="{ 'border-primary': isDropped }"
      style="position: relative"
      @click="
        () => {
          $router.push(
            `/thirdpartyrisksRA/assessments/${$route.params.assessmentId}/vendor/${vendorQuestionnaire.vendor._id}`
          );
        }
      "
    >
      <div class="d-flex align-items-center justify-content-between w-100 py-50 px-1">
        <div class="d-flex align-items-center justify-content-start">
          <b-badge variant="primary" class="mr-25">#{{ index + 1 }}</b-badge>
          <div
            style="width: 50vw"
            class="d-flex align-items-center justify-content-start px-1"
          >
            <!-- <p class="mb-0" style="font-size: 12px">Name</p> -->
            <h4
              class="mb-0 text-truncate"
              v-b-tooltip.hover.top.v-secondary
              :title="vendorQuestionnaire.vendor.name"
            >
              <span>{{ vendorQuestionnaire.vendor.name }}</span>
            </h4>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-end px-1">
          <!-- <div class="d-flex align-items-center justify-content-start p-50 rounded mr-3">
          <p class="mb-0 font-weight-bold mr-1">
            Questionnaires:<b-badge pill variant="warning" class="ml-50">{{
              vendorQuestionnaire.questionnaires.length.toString()
            }}</b-badge>
          </p>
        </div> -->

          <div
            class="d-flex flex-column align-items-start justify-content-start mr-3"
            v-if="
              vendorQuestionnaire.vendor.vendor_category &&
              vendorQuestionnaire.vendor.vendor_category !== null
            "
          >
            <p style="font-size: 12px" class="mb-25 font-weight-bold">Category</p>
            <b-badge
              style="max-width: 120px"
              variant="primary"
              class="text-truncate"
              v-b-tooltip.hover.top.v-info
              :title="vendorQuestionnaire.vendor.vendor_category"
              >vendorQuestionnaire.vendor.vendor_category</b-badge
            >
          </div>

          <progress-bar
            :value="Number(vendorQuestionnaire.completion)"
            :min="1"
            :max="100"
            title="Progress:"
          />
        </div>

        <div
          style="max-width: 25vw"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            @click.stop="
              () => {
                $router.push(
                  `/thirdpartyrisksRA/assessments/${$route.params.assessmentId}/vendor/${vendorQuestionnaire.vendor._id}`
                );
              }
            "
            pill
            variant="outline-primary"
            size="sm"
            >View Responses</b-button
          >
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import DonutBar from "../../../../components/DonutBar.vue";
import ProgressBar from "../../../../components/ProgressBar2.vue";
import ProgressBadge from "../../../../components/ProgressBadge.vue";
import Ripple from "vue-ripple-directive";
import {
  BAvatar,
  BButton,
  VBTooltip,
  BBadge,
  BOverlay,
  BPagination,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BSidebar,
} from "bootstrap-vue";
import EmptyTableSection from "../../../../components/EmptyTableSection.vue";
import ThirdPartyRisksRAMixins from "../../../../mixins/ThirdPartyRisksRAMixins";
import ResponseMixins from "../../../../mixins/ResponseMixins";
import QuestionnaireMixins from "../../../../mixins/QuestionnaireMixins";
import SidebarTemplate from "../../../../components/SidebarTemplate.vue";
import ResponseQuestionDetailsView from "./ResponseQuestionDetailsView.vue";

export default {
  mixins: [ThirdPartyRisksRAMixins, ResponseMixins, QuestionnaireMixins],
  components: {
    DonutBar,
    BAvatar,
    BButton,
    ProgressBar,
    BBadge,
    ProgressBadge,
    EmptyTableSection,
    BOverlay,
    BPagination,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    SidebarTemplate,
    ResponseQuestionDetailsView,
    BSidebar,
  },
  directives: { "b-tooltip": VBTooltip, Ripple },
  props: {
    vendorQuestionnaire: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    assessmentStatus: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isDropped: false,
      iconRotateAngle: 0,
      showOverlay: false,
      currentResponseIndex: 0,
      responses: [],
      selectedResponse: null,
      filters: {
        page: 1,
        search: null,
        status: null,
      },
      pagination: {
        perPage: 10,
        total: 0,
        from: 0,
      },
      complianceOptions: [
        { text: "--Filter By Compliance--", value: null },
        { text: "Yes", value: 1 },
        { text: "No", value: 2 },
        { text: "Not Applicable", value: 3 },
      ],
    };
  },

  watch: {
    isDropped(newValue) {
      if (newValue == true) {
        this.iconRotateAngle = 180;
        this.getAssessmentVendorResponsesAndSetData(
          this.$route.params.assessmentId,
          this.vendorQuestionnaire.vendor._id,
          this.filters
        );
      } else {
        this.iconRotateAngle = 0;
      }
    },
    filters: {
      handler: function (newValue) {
        this.getAssessmentVendorResponsesAndSetData(
          this.$route.params.assessmentId,
          this.vendorQuestionnaire.vendor._id,
          newValue
        );
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.getAssessmentVendorResponsesAndSetData(
        this.$route.params.assessmentId,
        this.vendorQuestionnaire.vendor._id,
        this.filters
      );
    },
    handleDetailsViewClick(response) {
      this.selectedResponse = response;
      this.openDetailsSidebar();
    },

    closeDetailsSidebar: function (id) {
      this.$root.$emit("bv::toggle::collapse", `sidebar-question-details-${id}`);
    },
    openDetailsSidebar: function (id) {
      this.$root.$emit("bv::toggle::collapse", `sidebar-question-details-${id}`);
    },

    getAssessmentVendorResponsesAndSetData(assessmentId, vendorId, params) {
      this.showOverlay = true;
      this.getAssessmentVendorResponses(assessmentId, vendorId, params)
        .then((res) => {
          console.log(res);
          this.responses = res.data.data.data;
          this.$set(this.pagination, "total", res.data.data.total);
          this.$set(this.pagination, "perPage", res.data.data.per_page);
          this.$set(this.pagination, "from", res.data.data.from);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleDropdownClick() {
      this.isDropped = !this.isDropped;
    },

    handleQuestionnaireClick(questionnaireId) {
      this.$router.push(
        `/thirdpartyrisksRA/assessments/${this.$route.params.assessmentId}/questionnaire/${questionnaireId}`
      );
    },

    handleQuestionClick(question) {
      this.setSelectedResponseData(question);
      this.openSidebar();
    },

    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Empty";
        }
      }
    },

    getComplianceValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Empty";
        }
      }
      return "Empty";
    },

    getResponseVariant(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "success";
          case 2:
            return "danger";
          case 3:
            return "light-dark";
          default:
            return "light-dark";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.transition {
  transition: transform 0.5s ease-in-out;
}

.expand-enter-active {
  transition: all 0.3s ease;
}
.expand-leave-active {
  transition: all 0.3s ease;
}
.expand-enter, .expand-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-50px);
  opacity: 0;
}
</style>

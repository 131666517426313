<template>
  <div style="width: 120px">
    <div class="d-flex justify-content-start align-items-center mb-50 px-25">
      <p class="font-weight-bold mb-0">
        {{ title }}
        <b-badge
          pill
          :variant="getVariant(Math.floor(getMapedValue(value, 1, max, 1, 5)))"
          >{{ value }}%</b-badge
        >
      </p>
    </div>
    <b-progress
      :variant="getVariant(Math.floor(getMapedValue(value, 1, max, 1, 5)))"
      :value="value"
      :max="max"
      striped
    />
  </div>
</template>

<script>
import { BProgress, VBTooltip, BBadge } from "bootstrap-vue";

export default {
  components: {
    BProgress,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },

  methods: {
    getVariant(status) {
      switch (status) {
        case 1:
          return "success";
        case 2:
          return "info";
        case 3:
          return "primary";
        case 4:
          return "warning";
        case 5:
          return "danger";
        default:
          return "success";
      }
    },
    getLightVariant(status) {
      switch (status) {
        case 1:
          return "light-success";
        case 2:
          return "light-info";
        case 3:
          return "light-primary";
        case 4:
          return "light-warning";
        case 5:
          return "light-danger";
        default:
          return "light-success";
      }
    },
    getTextVariant(status) {
      switch (status) {
        case 1:
          return "text-success";
        case 2:
          return "text-info";
        case 3:
          return "text-primary";
        case 4:
          return "text-warning";
        case 5:
          return "text-danger";
        default:
          return "text-success";
      }
    },
    getMapedValue(value, in_min, in_max, out_min, out_max) {
      const newValue =
        ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
      return newValue;
    },
    percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    },
  },
};
</script>

<style>
</style>
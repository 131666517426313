var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pb-75"},[_c('div',{staticClass:"d-flex align-items-start justify-content-between w-100"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start",staticStyle:{"max-width":"60%"}},[_c('h3',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(_vm._s(_vm.assessment.title))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.assessment.description))]),_c('div',{staticClass:"mt-1 d-flex align-items-center justify-content-end"},[(_vm.assessment.status === 3)?_c('b-button',{staticClass:"mr-50",attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.handlePublishClick(_vm.assessment._id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ArrowUpCircleIcon"}}),_vm._v("Publish")],1):_vm._e(),_c('b-button',{staticClass:"mr-50",attrs:{"size":"sm","variant":"info"},on:{"click":function () {
                  _vm.$router.push(("/thirdpartyrisksRA/assessments/edit/" + (_vm.assessment._id)));
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_vm._v("Edit")],1),_c('b-button',{attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function () {
                  _vm.$router.push(
                    ("/thirdpartyrisksRA/assessments/edit/" + (_vm.assessment._id) + "?startIndex=2")
                  );
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SendIcon"}}),_vm._v("Invite Third Party")],1)],1)]),_c('div',{staticClass:"d-flex flex-row align-items-center justify-content-end"},[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('donut-bar',{attrs:{"percentage":Number(_vm.assessment.completion.toFixed(2)),"displayValue":_vm.assessment.completion + '%',"size":"sm","uniqueId":_vm.assessment._id + 2,"colorsReversed":true}}),_c('p',{staticClass:"font-weight-bolder"},[_vm._v("Overall Progress")])],1)])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pb-0 border-bottom"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Third Parties "),_c('b-badge',{attrs:{"pill":"","variant":"warning","size":"sm"}},[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.assessment.groupedQuestionnaires.filter(function (item){ return item.vendor; }).length))])])],1)])]),_c('div',{staticClass:"pb-25 pt-0 w-100 row"},[_c('div',{staticClass:"app-fixed-search col-sm-6"},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[(_vm.filters.search == null || _vm.filters.search == '')?_c('feather-icon',{staticClass:"text-muted",attrs:{"icon":"SearchIcon"}}):_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function () {
                  _vm.filters.search = null;
                }}})],1),_c('b-form-input',{attrs:{"placeholder":"Search...","debounce":"500","size":"md"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1)]),_c('div',{staticClass:"card-body py-25 px-50"},[(
          _vm.assessment.groupedQuestionnaires &&
          _vm.assessment.groupedQuestionnaires != null &&
          _vm.assessment.groupedQuestionnaires.length > 0
        )?_vm._l((_vm.assessment.groupedQuestionnaires),function(vendorQuestionnaire,index){return _c('vendor-questionnaire-card',{key:vendorQuestionnaire.vendor?vendorQuestionnaire.vendor._id:index,attrs:{"vendorQuestionnaire":vendorQuestionnaire,"assessmentStatus":_vm.assessment.status,"index":index}})}):[_c('table',{staticClass:"w-100"},[_c('tbody',[_c('empty-table-section',{attrs:{"title":"Third Parties Empty"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"text-center"},[_vm._v(" It seems there are no Third Parties at the moment ")])]},proxy:true}])})],1)])]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }